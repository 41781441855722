<template>
  <v-container fluid>
    <v-row>
      <v-col class="offset-md-3 col-md-6">

        <v-card class="pa-8" shaped>
          <v-card-title>Inloggen</v-card-title>
          <LoginForm/>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue';

export default {
  name: 'LoginPage',
  components: {LoginForm},
};
</script>

<style scoped>

</style>