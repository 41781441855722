<template>
  <v-form
      ref="form"
      v-model="isValid"
      lazy-validation
      @submit.prevent="handleLogin()"
  >
    <VTextField
        v-model="form.email"
        label="E-mail address"
        required
        :rules="emailRules"
    />

    <VTextField
        v-model="form.password"
        label="Password"
        type="password"
        required
        :rules="passwordRules"
    />

    <v-btn
        class="mr-4"
        color="success"
        type="submit"
    >
      Login
    </v-btn>
  </v-form>
</template>

<script>
import LoginRequest from '@/api/endpoints/authorisation/login';
import {mapGetters} from 'vuex';

export default {
  name: 'LoginForm',
  data() {
    return {
      errorMessage: '',
      isLoading: false,
      showPassword: false,
      isValid: false,
      form: {
        email: '',
        password: '',
      },
      isRedirecting: false,
    };
  },
  computed: {
    emailRules: function () {
      return [
        v => !!v || 'E-mail address is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ];
    },
    passwordRules: function () {
      return [
        v => !!v || 'Password is required',
      ];
    },
    ...mapGetters({
      findError: 'Error/find',
    }),
  },
  methods: {
    handleLogin() {
      this.isLoading = true;
      LoginRequest(this.form.email, this.form.password)
          .then(res => {
            this.isRedirecting = true;
            this.redirectToAuthDispense(res.data.data.token);
          })
          .catch(() => this.errorMessage = this.findError('email'))
          .finally(() => this.isLoading = false);
    },
    redirectToAuthDispense(token) {
      const form = document.createElement('form');

      form.method = 'POST';
      form.action = process.env.VUE_APP_ROOT_API + '/auth/dispense';

      const redirectUriElement = document.createElement('input');
      redirectUriElement.name = 'redirect_uri';
      redirectUriElement.value = 'Dashboard';
      form.appendChild(redirectUriElement);

      const emailElement = document.createElement('input');
      emailElement.name = 'email';
      emailElement.value = this.form.email;
      form.appendChild(emailElement);

      const tokenElement = document.createElement('input');
      tokenElement.name = 'token';
      tokenElement.value = token;
      form.appendChild(tokenElement);

      document.body.appendChild(form);
      form.submit();
    },
  },
};

</script>
